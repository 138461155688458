import dayjs from "dayjs";

export const convertDateToNumber = (timestamp: number) => {
  const now = new Date(timestamp);
  const year = now.getFullYear();
  const month = ("0" + (now.getMonth() + 1)).slice(-2);
  const day = ("0" + now.getDate()).slice(-2);
  const formattedDate = year + month + day;

  return formattedDate;
};

export const convertStringToTimestamp = (dateString?: string) => {
  if (!dateString) return null;
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  return new Date(`${year}/${month}/${day}`).getTime();
};

export const convertStringToDateFormat = (dateString: string) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  return `${year}/${month}/${day}`;
};

export const convertStringToDate = (
  dateString: string | number,
  format?: string
) => {
  const date = new Date(dateString);

  if (date.getFullYear() > 1980) {
    return dayjs(date).format(format || "MMM DD, YYYY");
  } else {
    return "";
  }
};

export const convertStringToMonthDate = (
  dateString: string | number,
  linkSign?: string
) => {
  const date = new Date(dateString);
  return dayjs(date).format("YYYY/MM");
};

export const convertDateToString = (date: Date) => {
  if (date.getFullYear() > 2000) {
    return dayjs(date).format("MMM DD, YYYY");
  } else {
    return "";
  }
};

function getStartDateOfWeek(year: number, weekNumber: number): string {
  const januaryFirst = dayjs().year(year).startOf("year");
  const startDate = januaryFirst.add(weekNumber - 1, "week").startOf("week");
  return startDate.format("MMM DD, YYYY");
}

export const convertWeekToDate = (weekString: string) => {
  const [year, weekNumber] = weekString.split("-");
  return getStartDateOfWeek(parseInt(year), parseInt(weekNumber));
};

export const getWeekAndYear = (timestamp: Date) => {
  const date: any = new Date(timestamp);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));
  const year: number = date.getFullYear();
  const weekNumber = Math.ceil(
    ((date - (new Date(year, 0, 1) as any)) / 8.64e7 + 1) / 7
  );
  return { week: weekNumber, year: year };
};

import React from "react";
import { useContext } from "react";
import {
  CategoryOptions,
  GenderOptions,
  ReachOptions,
  SeasonOptions,
  TypeOptions,
} from "components/atoms/ExploreTrends/FilterPanel";
import { SortByOptions } from "components/molecules/ExploreTrends/ExploreTrendsFilters";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import capitalize from "lodash/capitalize";
import { ISelectValue, SelectOption } from "types";
import { beautifyStyleName } from "utils/normalizeString";
import { useTeam } from "contexts/TeamContext";
import {
  ITrendRankingData,
  useGetTrendData,
  useGetTrendsByBrand,
} from "../hooks/useGetTrendData";
import { useUser } from "contexts/UserContext";
import isArray from "lodash/isArray";
import { ExploreTrendsSortByOptions, Textiles } from "utils/constants";
import uniq from "lodash/uniq";
import { compareString } from "utils/compareString";
import { beautifyBasecat } from "utils/beautify";
import useTranslation from "next-translate/useTranslation";
import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import { isAuthorizedRoutes } from "utils/isAuthorizedRoutes";

const reachOrder: {
  [key: string]: number;
} = {
  major: 1,
  medium: 2,
  minor: 3,
};

const disableStyles = [
  "general_style_plus_body_part",
  "fw_lace",
  "fw_supra_styles",
  "fw_styles",
  "license_character",
];

const ForecastStateContext = React.createContext<any>({});

export const useForecastState = () => useContext(ForecastStateContext);

const ForecastStateProvider: React.FC = ({ children }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { pushWithState, currentTeam } = useTeam();
  const { user } = useUser();

  const params = router.query;

  const [sortBy, setSortBy] = useState<ISelectValue>(SortByOptions[0]);
  const [activeSection, setActiveSection] = useState<string>("style");
  const [gender, setGender] = useState<SelectOption>(GenderOptions[0]);
  const [season, setSeason] = useState<SelectOption>();
  const [reach, setReach] = useState<SelectOption[]>([ReachOptions[0]]);
  const [trend] = useState<SelectOption>();
  const [type, setType] = useState<SelectOption>();
  const [category, setCategory] = useState<SelectOption>();
  const [brand, setBrand] = useState<string>();
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [textileFilter, setTextileFilter] = useState<string | null>(null);
  const [styleFilter, setStyleFilter] = useState<string | null>(null);

  const isTargetRoute =
    router.route === "/[portalName]" ||
    router.route.includes("/forecast") ||
    router.route.includes("/home");

  useEffect(() => {
    setGender(
      GenderOptions?.find((item: any) => item.value === params.gender) ||
        GenderOptions[0]
    );
  }, [params.gender]);

  useEffect(() => {
    setSeason(SeasonOptions?.find((item: any) => item.value === params.season));
  }, [params.season]);

  useEffect(() => {
    if (params.reach) {
      const reachValues = (params.reach as string).split("/");
      setReach(
        ReachOptions?.filter((item: any) => reachValues.includes(item.value))
      );
    } else {
      setReach([ReachOptions[0]]);
    }
  }, [params.reach]);

  useEffect(() => {
    setBrand(params?.brand?.toString() || "");
  }, [params.brand]);

  useEffect(() => {
    setType(TypeOptions?.find((item: any) => item.value === params.type));
  }, [params?.type]);

  useEffect(() => {
    if (params?.basecat) {
      setCategory({
        name: capitalize(beautifyStyleName(params.basecat.toString())),
        value: params?.basecat.toString(),
      });
    } else {
      setCategory(CategoryOptions[2]);
    }
  }, [params?.basecat]);

  const sortOrder = useMemo(() => {
    if (type?.value) {
      return type?.value === "upward" ? "desc" : "asc";
    } else {
      return sortBy?.value === ExploreTrendsSortByOptions.GHL ||
        sortBy?.value === ExploreTrendsSortByOptions.GLH
        ? "desc"
        : "asc";
    }
  }, [type?.value, sortBy?.value]);

  const { data: trendStyles, isLoading: isLoadingTrendStyles } =
    useGetTrendData(
      "forecast",
      {
        gender: gender?.value || GenderOptions[0].value,
        season: season?.value,
        year: 2,
        catgroup: "C",
        sort_order: sortOrder,
        reach_label: reach?.map((item) => item.value).join(",") || "major",
        basecat:
          category?.value.replaceAll(" ", "") || CategoryOptions[2].value,
        analysis_type: "style",
        limit: 200,
      },
      {
        enabled: !!user && !!category?.value && isTargetRoute && !!currentTeam,
      }
    );

  const { data: subcatTrends = [], isLoading: isLoadingSubcatTrends } =
    useGetTrendData(
      "forecast",
      {
        gender: gender?.value || GenderOptions[0].value,
        season: season?.value,
        year: 2,
        catgroup: "C",
        sort_order: sortOrder,
        reach_label: reach?.map((item) => item.value).join(",") || "major",
        basecat:
          category?.value.replaceAll(" ", "") || CategoryOptions[0].value,
        analysis_type: "subcat",
        limit: 200,
      },
      {
        enabled: !!user && !!category?.value && isTargetRoute && !!currentTeam,
      }
    );

  const {
    data: trendStylesByBrand = [],
    isLoading: isLoadingTrendStylesByBrand,
  } = useGetTrendsByBrand(
    {
      analysis_type: trend?.value || "style",
      brand: brand,
      trend_type: season?.value,
      season_name: "forecast",
      basecat: category?.value || CategoryOptions[0].value,
      gender: gender?.value || GenderOptions[0].value,
      year_delta: "2",
    },
    {
      enabled: !!brand && !!category?.value && isTargetRoute && !!currentTeam,
    }
  );

  const orderedData = useMemo(() => {
    const data = !!brand ? trendStylesByBrand : trendStyles;

    if (!isArray(data)) return [];

    const filteredData = data?.filter((item) => {
      return (
        type?.value
          ? type?.value === "downward" && !brand
          : sortBy?.value === ExploreTrendsSortByOptions.DLH ||
            sortBy?.value === ExploreTrendsSortByOptions.DHL
      )
        ? item.pct_change < 0
        : item.pct_change >= 0;
    });

    filteredData.sort((a, b) => {
      if (reachOrder[a.reach_label] < reachOrder[b.reach_label]) return -1;
      if (reachOrder[a.reach_label] > reachOrder[b.reach_label]) return 1;
      return sortBy?.value === ExploreTrendsSortByOptions.GLH ||
        sortBy?.value === ExploreTrendsSortByOptions.DLH
        ? a.pct_change - b.pct_change
        : b.pct_change - a.pct_change;
    });

    return filteredData?.filter(
      (item) =>
        (!!brand ? item.pct_change > 0 : true) &&
        (searchKeyword === "" ||
          item.name.toLowerCase().includes(searchKeyword.toLowerCase()))
    );
  }, [trendStyles, type, trendStylesByBrand, brand, searchKeyword, sortBy]);

  const filteredSubcatTrend = useMemo(() => {
    const filteredData = subcatTrends?.filter((item) => {
      return (
        type?.value
          ? type?.value === "downward" && !brand
          : sortBy?.value === ExploreTrendsSortByOptions.DLH ||
            sortBy?.value === ExploreTrendsSortByOptions.DHL
      )
        ? item.pct_change < 0
        : item.pct_change >= 0;
    });

    filteredData?.sort((a: ITrendRankingData, b: ITrendRankingData) => {
      if (reachOrder[a.reach_label] < reachOrder[b.reach_label]) return -1;
      if (reachOrder[a.reach_label] > reachOrder[b.reach_label]) return 1;
      return sortBy?.value === ExploreTrendsSortByOptions.GLH ||
        sortBy?.value === ExploreTrendsSortByOptions.DLH
        ? a.pct_change - b.pct_change
        : b.pct_change - a.pct_change;
    });

    return filteredData.filter(
      (item: ITrendRankingData) =>
        searchKeyword === "" ||
        item.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );
  }, [subcatTrends, brand, type, searchKeyword, sortBy]);

  const forecastFilters = {
    basecat: category?.value,
    gender: gender?.value,
    season: season?.value,
    reach: reach?.map((item) => item.value).join("/"),
    trend: trend?.value,
    brand: brand,
    type: type?.value,
  };

  // filtering trends based on filters for both styles and textiles and themes
  const { textileTrends, filteredTextileTrends } = useMemo(() => {
    const textileTrends = orderedData?.filter((item) =>
      Textiles.includes(item.style_key)
    );

    const filteredTextileTrends = textileTrends.filter(
      (item) => textileFilter === null || textileFilter === item.style_key
    );

    return {
      textileTrends,
      filteredTextileTrends,
    };
  }, [orderedData, textileFilter]);

  const { styleTrends, filteredStyleTrends } = useMemo(() => {
    const styleTrends = orderedData?.filter(
      (item) => !Textiles.includes(item.style_key) && item.style_key !== "theme"
    );

    const filteredStyleTrends = styleTrends.filter(
      (item) => styleFilter === null || styleFilter === item.style_key
    );

    return { styleTrends, filteredStyleTrends };
  }, [orderedData, styleFilter]);

  const themeTrends = useMemo(
    () => orderedData?.filter((item) => item.style_key === "theme"),
    [orderedData]
  );

  // initial textile options and style options
  const textileOptions = useMemo(
    () => [
      ...(textileTrends.length > 0 ? [{ name: t("All"), value: null }] : []),
      ...uniq(textileTrends.map((item) => item.style_key))
        .sort((a, b) => compareString(a, b))
        .map((item) => ({
          name: capitalize(beautifyBasecat(item)),
          value: item,
        })),
    ],
    [textileTrends]
  );

  useEffect(() => {
    if (filteredTextileTrends?.length === 0) {
      const textile = textileOptions.find(
        (item) => item.value === params.textile
      );
      setTextileFilter(textile?.value || null);
    }
  }, [textileOptions]);

  const styleOptions = useMemo(
    () => [
      ...(styleTrends.length > 0 ? [{ name: t("All"), value: null }] : []),
      ...uniq(styleTrends.map((item) => item.style_key))
        .filter((item) => !disableStyles.includes(item))
        .map((item) => ({
          name:
            item === "supra_styles"
              ? "Concept"
              : capitalize(beautifyBasecat(item)),
          value: item,
        }))
        .sort((a, b) => compareString(a.name, b.name)),
    ],
    [styleTrends]
  );

  useEffect(() => {
    if (filteredStyleTrends?.length === 0) {
      const styleOption = styleOptions.find(
        (item) => item.value === params.style
      );

      setStyleFilter(styleOption?.value || null);
    }
  }, [styleOptions]);

  const handleChangeStyleFilter = (value: string | null, source: string) => {
    if (source === "textile") {
      setTextileFilter(value);
    } else if (source === "style") {
      setStyleFilter(value);
    }

    pushWithState(
      "/forecast",
      pickBy(
        {
          brand: params?.brand,
          gender: params?.gender,
          basecat: params?.basecat,
          textile: params?.textile,
          style: params?.style,
          season: params?.season,
          reach: params?.reach,
          type: params?.type,
          [source]: value,
        },
        (value) => !isEmpty(value)
      )
    );
  };

  const isLoadingTrendData = useMemo(
    () => isLoadingTrendStyles || isLoadingTrendStylesByBrand,
    [isLoadingTrendStyles, isLoadingTrendStylesByBrand]
  );

  const countData = useMemo(
    () => ({
      subcat: subcatTrends?.length || 0,
      textile: textileTrends?.length || 0,
      style: styleTrends?.length || 0,
      theme: themeTrends?.length || 0,
    }),
    [subcatTrends, textileTrends, styleTrends, themeTrends]
  );

  const adjustedSortByOption = useMemo(() => {
    return !type?.value
      ? SortByOptions
      : type?.value === "upward" || !!brand
      ? SortByOptions.slice(0, 2)
      : SortByOptions.slice(2, 4);
  }, [type?.value, SortByOptions, brand]);

  useEffect(() => {
    const filteredData = adjustedSortByOption.map(
      (item) => item.value as string
    );
    if (!filteredData.includes(sortBy.value)) {
      setSortBy(adjustedSortByOption[0]);
    }
  }, [adjustedSortByOption]);

  return (
    <ForecastStateContext.Provider
      value={{
        brand,
        styleOptions,
        textileOptions,
        searchKeyword,
        setSearchKeyword,
        setStyleFilter,
        setTextileFilter,
        handleChangeStyleFilter,
        isLoadingTrendData,
        countData,
        forecastFilters,
        filteredSubcatTrend,
        isLoadingSubcatTrends,
        reach,
        season,
        gender,
        category,
        styleFilter,
        type,
        textileFilter,
        activeSection,
        setActiveSection,
        adjustedSortByOption,
        sortBy,
        setSortBy,
        filteredTextileTrends,
        filteredStyleTrends,
        themeTrends,
      }}
    >
      {children}
    </ForecastStateContext.Provider>
  );
};

export default ForecastStateProvider;
